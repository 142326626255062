'use client';

import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { VerticalDivider } from '@/components/VerticalDivider';
import { CameraMapOptions } from '@/components/map/types';
import SearchIndicator from '@/components/search/SearchIndicator';
import LocationSearchInput from '@/components/search/SearchInput';
import { SearchType } from '@/components/search/search-type.enum';
import {
  createLocalizationQueryParams,
  getRoute,
} from '@/components/search/utils';
import { useRouter } from '@/hooks/useRouter';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';
import { SimpleLocationSearchDto } from '@/lib/locations/dto/simple-location-search.dto';
import { createLocationUrl } from '@/utils/locations';
import { stringifyUrl } from '@/utils/stringify-url';

import SelectSearchType from './SelectSearchType';

export default function HomeSearchInput() {
  const t = useTranslations('home');
  const screenSize = useScreenSize();
  const router = useRouter();
  const [searchType, setSearchType] = useState(SearchType.Location);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickResultItem = (coords: CameraMapOptions, query: string) => {
    setIsLoading(true);
    router.push(
      stringifyUrl(
        getRoute(searchType),
        createLocalizationQueryParams(coords, query)
      )
    );
  };

  const handleClickSearchButton = () => {
    setIsLoading(true);
    router.push(getRoute(searchType));
  };

  const handleClickLocation = (location: SimpleLocationSearchDto) => {
    setIsLoading(true);
    router.push(createLocationUrl(location));
  };

  return (
    <div className="flex flex-col md:flex-row w-full sm:max-w-[572px] h-[50px] gap-y-5">
      <div className="flex items-center py-2 pl-5 bg-white md:rounded-l-full max-md:rounded-full min-w-max">
        <SelectSearchType value={searchType} onChange={setSearchType} />
        <VerticalDivider className="hidden h-full md:block" />
      </div>
      <LocationSearchInput
        className="relative flex w-full rounded-3xl"
        controlClassName="pl-5 md:rounded-l-none"
        placeholder={t('search-placeholder')}
        isScrollOnFocus={screenSize < ScreenSize.LG}
        rightIndicator={
          <SearchIndicator
            onClick={handleClickSearchButton}
            isLoading={isLoading}
          />
        }
        searchType={searchType}
        onClickResultItem={handleClickResultItem}
        onClickLocation={handleClickLocation}
      />
    </div>
  );
}
